import select from "select";
const CLIPBOARD_COPY_TIMEOUT = 5e3;
$(() => {
  $(document).on("click", "[data-clipboard-copy]", (ev) => {
    const $el = $(ev.currentTarget);
    if (!$el.data("clipboard-copy") || $el.data("clipboard-copy").length < 1) {
      return;
    }
    const $input = $($el.data("clipboard-copy"));
    let selectedText = $el.data("clipboard-content") || "";
    if (selectedText === "" && $input.is("input, textarea, select")) {
      selectedText = select($input[0]);
    }
    let $msgEl = $el;
    if ($msgEl.text() === "") {
      $msgEl = $input;
    }
    if (!selectedText || selectedText.length < 1) {
      return;
    }
    const $temp = $(`<textarea>${selectedText}</textarea>`).css({
      width: 1,
      height: 1
    });
    $el.after($temp);
    $temp.select();
    const copyDone = () => {
      $temp.remove();
      $el.focus();
    };
    try {
      if (!document.execCommand("copy")) {
        return;
      }
    } catch (err) {
      copyDone();
      return;
    }
    copyDone();
    const label = $el.data("clipboard-copy-label");
    if (label) {
      let to = $el.data("clipboard-copy-label-timeout");
      if (to) {
        clearTimeout(to);
      }
      if (!$el.data("clipboard-copy-label-original")) {
        $el.data("clipboard-copy-label-original", $msgEl.html());
      }
      $msgEl.html(label);
      to = setTimeout(() => {
        $msgEl.html($el.data("clipboard-copy-label-original"));
        $el.removeData("clipboard-copy-label-original");
        $el.removeData("clipboard-copy-label-timeout");
      }, CLIPBOARD_COPY_TIMEOUT);
      $el.data("clipboard-copy-label-timeout", to);
    }
    let message = $el.data("clipboard-copy-message");
    if (message) {
      let $msg = $el.data("clipboard-message-element");
      if ($msg) {
        if ($msg.html() === message) {
          message += "&nbsp;";
        }
      } else {
        $msg = $('<div aria-role="alert" aria-live="assertive" aria-atomic="true" class="sr-only"></div>');
        $msgEl.append($msg);
        $el.data("clipboard-message-element", $msg);
      }
      $msg.html(message);
    }
  });
});
